import React, { Component } from "react";

import styles from "../../../components/Client/styles.module.css";
import SignatureCanvas from "react-signature-canvas";
import { Row, Col, Container } from "react-bootstrap";
import $ from "jquery";
import PROMO_A from "../../../assets/images/error-404.svg";
import {
  getClientFormVerification,
  saveClientResponseVerification,
} from "../../../config/forms_api_calls";
import { REPLACECONTENTCLIENT } from "../../../config/utility";
import {
  THEME_COLOR,
  THEME_TEXT_COLOR,
  VENDOR_SIGNATURE,
} from "../../../config/config";
import "../../../components/Client/agreement.css";
import HelperClass from "../../../config/helperClass";
import MutextField from "@material-ui/core/TextField";
import FormRendererClient from "../../../components/Client/formRenderClient";
import { tableLoaderAgreement } from "../../../components/ContentLoaders/table_loader_agreement";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import html2canvas from "html2canvas";
const auth = new HelperClass();

export default class FormVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      successMessage: "",
      tableLoaderAgreement: true,
      errorMessageSignee: "",
      successMessageSignee: "",
      replacable_content: "",
      client_accountno: "",
      form_accountno: "",
      form_token: "",
      formData: "",
      form_title: "",
      form_desc: "",
      form_submitted: "3",
      responseMessage: "",
      client_details: [],
      submission_status: "",
      allow_submit: 1,
      link_form: false,
      external_link: "",
    };
  }

  async componentDidMount() {
    this.setState({ form_token: this.props.match.params.form_token });
    let apiHit = await getClientFormVerification(
      this.props.match.params.form_token
    );
    console.log("apiHit.data: ", apiHit.data);
    if (
      apiHit.data.status === 404 &&
      apiHit.data.message === "Invalid Credientials"
    ) {
      //window.location.replace("/error");
      this.setState({
        errorMessage: "Invalid Credentials.",
        successMessage: "",
        tableLoaderAgreement: false,
        form_submitted: 2,
      });
    } else if (
      apiHit.data.status === 404 &&
      apiHit.data.message === "no_record"
    ) {
      this.setState({
        errorMessage:
          "Oops! Your temporary URL has been expired or does not exists.",
        successMessage: "",
        tableLoaderAgreement: false,
        form_submitted: 2,
      });
    } else if (
      apiHit.data.status === 404 &&
      apiHit.data.message === "already_signed"
    ) {
      this.setState({
        errorMessage: "Oops! Agreement already signed.",
        successMessage: "",
        tableLoaderAgreement: false,
        form_submitted: 2,
      });
    } else if (
      apiHit.data.status === 404 &&
      apiHit.data.message === "form_submitted"
    ) {
      this.setState({
        errorMessage: "Oops! Form already submitted.",
        successMessage: "",
        tableLoaderAgreement: false,
        form_submitted: 1,
      });
    } else if (apiHit.data.status === 200) {
      const groupsData = apiHit.data.data[0]["groups"];
      const groups = groupsData ? JSON.parse(groupsData) : [];
      this.setState({
        formData: apiHit.data.data[0]["form_content"],
        form_title: apiHit.data.data[0]["form_title"],
        form_desc: apiHit.data.data[0]["form_desc"],
        allow_submit: apiHit.data.data[0]["allow_submit"],
        link_form: apiHit.data.data[0]["link_form"],
        external_link: apiHit.data.data[0]["external_link"],
        groups: groups,
        client_accountno: apiHit.data.client_accountno,
        form_accountno: apiHit.data.form_accountno,
        tableLoaderAgreement: false,
        form_submitted: apiHit.data.is_submitted,
        client_details: apiHit.data.client_data,
      });
    } else {
      this.setState({
        errorMessage:
          "Oops! There is some error while processing your request.",
        successMessage: "",
        tableLoaderAgreement: false,
        form_submitted: 2,
      });
    }
  }

  formatPhoneNumber = (phoneNumber) => {
    const phoneNumberObj = parsePhoneNumberFromString(phoneNumber);
    if (phoneNumberObj) {
      if (phoneNumberObj.country === "US") {
        const nationalNumber = phoneNumberObj
          .formatNational()
          .replace(/\D/g, ""); // e.g. "2123727200"
        const areaCode = nationalNumber.slice(0, 3);
        const firstPart = nationalNumber.slice(3, 6);
        const secondPart = nationalNumber.slice(6);
        return `(${areaCode}) ${firstPart}-${secondPart}`;
      }
      return phoneNumberObj.formatInternational();
    }
    return phoneNumber; // Return original number if parsing fails
  };

  clear = () => {
    this.signaturePad.clear();
    $("#divImgSig").hide();
    $(".clsimgSig").attr("src", "");
    $(".clsimgSig").hide();
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.value : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  handleSubmitForm = (formData) => {
    let response = JSON.stringify(formData);
    this.saveResponse(response, this.state.form_accountno);
  };

  saveResponse = async (response, formAccNo) => {
    const { form_token, form_title, form_desc, client_accountno } = this.state;
    window.scrollTo({ top: 0, behavior: "smooth" });
    const submitFormReponse = await saveClientResponseVerification(
      client_accountno,
      response,
      formAccNo,
      form_token,
      form_title,
      form_desc
    );
    console.log("saveResponse response: ", submitFormReponse.data);

    if (
      submitFormReponse.data.status === 403 ||
      submitFormReponse.data.errors === "authentication missing" ||
      submitFormReponse.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (submitFormReponse.data.status === 404) {
      //window.location.replace("/error");
      this.setState({
        responseMessage:
          '<div class="alert alert-danger alert-icon mb-3"><em class="icon ni ni-cross-circle"></em> There is some error while submitting the form.</div>',
      });
    } else if (submitFormReponse.data.status === 405) {
      //window.location.replace("/error");
      this.setState({
        responseMessage:
          '<div class="alert alert-danger alert-icon mb-3"><em class="icon ni ni-cross-circle"></em> There is some error while submitting the form.</div>',
      });
    } else if (submitFormReponse.data.status === 200) {
      this.setState({
        responseMessage:
          '<div class="alert alert-success alert-icon mb-3"><em class="icon ni ni-check-circle"></em> Your response submitted succesfully!</div>',
      });
      setTimeout(() => {
        window.location.reload();
      }, 3500);
    }
    setTimeout(() => {
      this.setState({
        errorMessageSaveResponse: "",
        responseMessage: "",
      });
    }, 2000);
  };

  render() {
    return (
      <div className="nk-content form_main_container">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <em className="icon ni ni-list-round"></em> Submit Form
                    </h3>
                  </div>
                </div>
              </div>

              {this.state.successMessage !== "" ? (
                <>
                  <div className="example-alert">
                    <div className="alert alert-pro alert-success">
                      <div className="alert-text">
                        <h4>Success</h4>
                        <p>{this.state.successMessage}</p>
                      </div>
                    </div>
                  </div>
                  <center>
                    <a
                      onClick={this.close_window}
                      className="btn btn-primary mt-2"
                      style={{ color: "#fff" }}
                    >
                      Close Window
                    </a>
                  </center>
                </>
              ) : null}
              {this.state.tableLoaderAgreement === true ? (
                tableLoaderAgreement()
              ) : (
                <>
                  {this.state.link_form === 1 ? (
                    <div className="nk-block mt-4">
                      <div className="nk-block-content nk-error-ld">
                        <h3 className="nk-error-title text-center">
                          Link Only
                        </h3>
                        <div className="alert alert-light text-dark">
                          This form contains an external link.{" "}
                          <a href={this.state.external_link} target="_blank">
                            Click Here
                          </a>{" "}
                          to be redirected to the specified destination.
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {this.state.form_submitted === 0 ? (
                        <>
                          {this.state.formData &&
                          this.state.formData.length > 0 ? (
                            <div className="nk-block mt-4">
                              <FormRendererClient
                                formData={JSON.parse(this.state.formData)}
                                formTitle={this.state.form_title}
                                formDescription={this.state.form_desc}
                                onSubmit={this.handleSubmitForm}
                                groups={this.state.groups}
                                responseMessage={this.state.responseMessage}
                                clientData={this.state.client_details}
                                allow_submit={this.state.allow_submit}
                                // id={this.state.forms[0].id}
                                // setShowResponse={(value) => this.setState({ showResponse: value })}
                              />
                            </div>
                          ) : null}
                        </>
                      ) : this.state.form_submitted === 1 ? (
                        <div className="nk-block mt-4">
                          <div className="nk-block-content nk-error-ld text-center">
                            <h3 className="nk-error-title">Form Submitted</h3>
                            <div className="alert alert-light alert-icon text-dark">
                              Thank you for submitting your form. We have
                              successfully received your information and are
                              processing it. Our team will review the details
                              and get back to you shortly if further information
                              is needed. We appreciate your time and effort in
                              providing us with the necessary details.
                            </div>
                          </div>
                        </div>
                      ) : this.state.form_submitted === 2 ? (
                        <div className="nk-block mt-4">
                          <div className="nk-block-content nk-error-ld text-center">
                            <h1 className="nk-error-head">404</h1>
                            <h3 className="nk-error-title">Form Unavailable</h3>
                            <p className="nk-error-text">
                              We are very sorry for inconvenience. It looks like
                              you’re try to access a form that either has been
                              expired or already submitted.
                            </p>
                          </div>
                        </div>
                      ) : null}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
