import React, { useState, useEffect, useRef } from "react";
import styles from "./styles.module.css";
import SignatureCanvas from "react-signature-canvas";
import { Row, Col, Container } from "react-bootstrap";
import $ from "jquery";
import {
  TextField,
  Checkbox,
  Radio,
  FormControlLabel,
  FormGroup,
  FormControl,
  Button,
  FormHelperText,
} from "@material-ui/core";
import { APP_LIVE_URL, ADMIN_URL } from "../../config/config";
import {
  DateTimePicker,
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import axios from "axios";
import DateFnsUtils from "@date-io/date-fns";
import { CopyToClipboard } from "react-copy-to-clipboard";
import html2canvas from "html2canvas";

const populatedynamicValues = (clientsData, value_name) => {
  if (clientsData && clientsData.length > 0) {
    const valueMap = {
      fullname: clientsData[0].name,
      company: clientsData[0].comp_name,
      email: clientsData[0].email,
      phone: clientsData[0].phone,
      address: clientsData[0].address,
      appartment: clientsData[0].address2,
      city: clientsData[0].city,
      state: clientsData[0].state,
      zip: clientsData[0].zip,
      country: clientsData[0].country,
    };

    if (value_name === "full_address") {
      return [
        clientsData[0].address,
        clientsData[0].address2,
        clientsData[0].city,
        clientsData[0].state,
        clientsData[0].zip,
        clientsData[0].country,
      ]
        .filter(Boolean)
        .join(", ");
    }

    return valueMap[value_name] || "";
  } else {
    return "";
  }
};

const loadFonts = () => {
  const link = document.createElement("link");
  link.href =
    "https://fonts.googleapis.com/css2?family=Amita&family=Bad+Script&family=Damion&family=Dancing+Script&family=Felipa&family=Julee&family=Kaushan+Script&family=Lugrasimo&family=Pacifico&family=Sofia&family=Yellowtail&display=swap";
  link.rel = "stylesheet";
  document.head.appendChild(link);
};

const cursiveFonts = [
  { name: "Your Signature", value: "'Amita', cursive" },
  { name: "Your Signature", value: "'Bad Script', cursive" },
  { name: "Your Signature", value: "'Damion', cursive" },
  { name: "Your Signature", value: "'Dancing Script', cursive" },
  { name: "Your Signature", value: "'Felipa', cursive" },
  { name: "Your Signature", value: "'Julee', cursive" },
  { name: "Your Signature", value: "'Kaushan Script', cursive" },
  { name: "Your Signature", value: "'Lugrasimo', cursive" },
  { name: "Your Signature", value: "'Pacifico', cursive" },
  { name: "Your Signature", value: "'Sofia', cursive" },
  { name: "Your Signature", value: "'Yellowtail', cursive" },
  // Add more cursive fonts here
];

const FormRendererAgent = ({
  formData,
  formTitle,
  formDescription,
  onSubmit,
  groups,
  responseMessage,
  clientData,
  allow_submit,
}) => {
  //console.log("formData: ", formData);
  const [formState, setFormState] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [signatureType, setSignatureType] = useState("draw");
  const [signatureText, setSignatureText] = useState("");
  const [signatureClass, setSignatureClass] = useState("'Amita', cursive");
  const sigPadRefs = useRef([]);

  useEffect(() => {
    const initialState = {};
    // Assuming questions is an array and you need to access its nested `questions` property
    formData.forEach((section) => {
      section.questions.forEach((question) => {
        if (question.dynamicValue) {
          initialState[question.id] = populatedynamicValues(
            clientData,
            question.dynamicValue
          );
        } else {
          initialState[question.id] = ""; // or any default value if needed
        }
      });
    });
    setFormState(initialState);
  }, [clientData, formData]);

  const hasUngroupedQuestions = formData.some((form) =>
    form.questions.some((q) => !groups?.flat().includes(q.id))
  );

  const ungroupedQuestions = formData
    .flatMap((form) => form.questions)
    .filter((question) => !groups.flat().includes(question.id));

  const handleChange = (questionId, value) => {
    console.log("questionId  value: " + questionId + "  " + value);
    setFormState({
      ...formState,
      [questionId]: value,
    });
    setFormErrors({
      ...formErrors,
      [questionId]: "",
    });
  };

  const handleChangeSignature = (questionId, value) => {
    setSignatureText(value);
  };

  const handleChangeFixed = (questionId, value) => {
    setFormState({
      ...formState,
    });
  };

  const handleCheckboxChange = (questionId, option, isChecked) => {
    const currentOptions = formState[questionId] || [];
    if (isChecked) {
      setFormState({
        ...formState,
        [questionId]: [...currentOptions, option],
      });
    } else {
      setFormState({
        ...formState,
        [questionId]: currentOptions.filter((opt) => opt !== option),
      });
    }
    setFormErrors({
      ...formErrors,
      [questionId]: "",
    });
  };

  const handleFileChange = (questionId, event) => {
    if (event.target && event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file) {
        const formData = new FormData();
        formData.append("file", file);

        axios
          .post(
            `${process.env.REACT_APP_API_URL}forms/form_image_response`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            console.log("handleFileChange: ", response);
            const imageUrl = response.data.default;
            setFormState((prevFormState) => ({
              ...prevFormState,
              [questionId]: imageUrl,
            }));
            setFormErrors((prevFormErrors) => ({
              ...prevFormErrors,
              [questionId]: "",
            }));
          })
          .catch((error) => {
            console.error("There was an error uploading the image!", error);
          });
      }
    } else {
      console.error("No file selected or event target is undefined.");
    }
  };

  const handleSubmit = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const requiredQuestions = formData.flatMap((form) =>
      form.questions.filter((question) => question.required)
    );
    let hasErrors = false;

    requiredQuestions.forEach((question) => {
      if (!formState[question.id]) {
        hasErrors = true;
      }
    });

    if (hasErrors) {
      setErrorMessage("Please fill out all required fields.");
      setTimeout(() => {
        setErrorMessage("");
      }, 2500);
      return;
    }
    setLoading(true);
    //console.log("formData Before: ", formData);
    const formDataToSend = formData.map((form) => ({
      title: form.title,
      id: form.id,
      questions: form.questions.map((question) => ({
        ...question,
        //answer: formState[question.id],
        answer:
          question.answerType === "fixed"
            ? question.answer
            : formState[question.id],
      })),
      errorMessage: form.errorMessage,
    }));
    //console.log("formDataToSend: ", formDataToSend);
    onSubmit(formDataToSend);
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  };

  /*const handleNextStep = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    console.log("validateCurrentGroup(): ", validateCurrentGroup());
    if (validateCurrentGroup()) {
      setCurrentStep(currentStep + 1);
    } else {
      setErrorMessage(
        "Please fill out all required fields in the current group."
      );
      setTimeout(() => {
        setErrorMessage("");
      }, 3500);
    }
  };*/

  /*const validateCurrentGroup = () => {
    const currentGroup = groups[currentStep];
    if (!currentGroup) return false;

    const hasEmptyRequiredFields = currentGroup.questions.some((questionId) => {
      const question = formData[0].questions.find((q) => q.id === questionId);
      
      if (question) {
        //return question.required && !formState[question.id];
        return question;
      }
    });

    return !hasEmptyRequiredFields;
  };*/

  const handleNextStep = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    const { isValid, missingFields } = validateCurrentGroup();

    if (isValid) {
      setCurrentStep(currentStep + 1);
    } else {
      const fieldsList = missingFields.join(", ");
      setErrorMessage(`Please fill out all required fields: ${fieldsList}`);
      setTimeout(() => {
        setErrorMessage("");
      }, 4500);
    }
  };

  const validateCurrentGroup = () => {
    const currentGroup = groups[currentStep];
    if (!currentGroup) return { isValid: false, missingFields: [] };

    const missingFields = currentGroup.questions
      .map((questionId) => {
        const question = formData[0].questions.find((q) => q.id === questionId);

        if (question && question.required && !formState[question.id]) {
          return question.questionText; // Return the question text (field name) if it's required and empty
        }
        return null;
      })
      .filter(Boolean); // Filter out any null values

    return {
      isValid: missingFields.length === 0,
      missingFields: missingFields,
    };
  };

  const clearSignature = (index) => {
    if (sigPadRefs.current[index]) {
      sigPadRefs.current[index].clear();
      $("#divSig" + index).hide();
      $(".clsimgSig").attr("src", "");
      $(".clsimgSig").hide();
    }
  };

  const previewSignature = (index) => {
    if (sigPadRefs.current[index]) {
      const dataUrl = sigPadRefs.current[index].toDataURL();
      var data = sigPadRefs.current[index]
        .getTrimmedCanvas()
        .toDataURL("image/svg+xml");
      $("#divSig" + index).show();
      $(".clsimgSig").show();
      $(".clsimgSig").attr("src", data);
      console.log("Signature Preview:", dataUrl);
      // You can do something with the dataUrl, like displaying it or saving it
    }
  };

  const saveSignature = async (index) => {
    const signaturePad = sigPadRefs.current[index];

    if (signaturePad && !signaturePad.isEmpty()) {
      try {
        // Generate the trimmed signature as a base64 string
        const trimmedDataUrl = signaturePad
          .getTrimmedCanvas()
          .toDataURL("image/svg+xml");

        // Prepare the JSON payload
        const payload = {
          signature: trimmedDataUrl,
        };

        // Send the signature data as JSON to the server
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}forms/form_signature_response`,
          JSON.stringify(payload),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        // Check if response status is 200 and contains the expected data
        if (response.status === 200 && response.data.default) {
          // Extract the image URL from the response
          const imageUrl = response.data.default;

          // Update the form state with the new image URL
          setFormState((prevFormState) => ({
            ...prevFormState,
            [index]: imageUrl,
          }));

          // Clear any previous errors related to this signature
          setFormErrors((prevFormErrors) => ({
            ...prevFormErrors,
            [index]: "",
          }));

          console.log(`Signature ${index + 1} saved successfully:`, imageUrl);
        } else {
          throw new Error(
            `Unexpected response format or status: ${response.status}`
          );
        }
      } catch (error) {
        // Handle errors and update state
        console.error(
          `Error uploading the signature for index ${index + 1}:`,
          error.message
        );

        // Optionally, update form errors state to show the error message
        setFormErrors((prevFormErrors) => ({
          ...prevFormErrors,
          [index]: error.message || "Failed to save signature",
        }));
        setErrorMessage("Failed to save signature. Please try again.");
        setTimeout(() => {
          setErrorMessage("");
        }, 2500);
      }
    } else {
      setErrorMessage("Signature is empty.");
      setTimeout(() => {
        setErrorMessage("");
      }, 2500);
      console.log(`Signature ${index + 1} is empty or does not exist.`);
    }
  };

  const saveChooseImage = async (index) => {
    const element = document.getElementById("styled-text-container" + index);
    if (element) {
      html2canvas(element, { scale: 2, backgroundColor: null }).then(
        async (canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const payload = {
            signature: imgData,
          };

          // Send the signature data as JSON to the server
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}forms/form_signature_choose_response`,
            JSON.stringify(payload),
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          if (response.status === 200 && response.data.default) {
            // Extract the image URL from the response
            const imageUrl = response.data.default;

            // Update the form state with the new image URL
            setFormState((prevFormState) => ({
              ...prevFormState,
              [index]: imageUrl,
            }));

            // Clear any previous errors related to this signature
            setFormErrors((prevFormErrors) => ({
              ...prevFormErrors,
              [index]: "",
            }));

            console.log(`Signature ${index + 1} saved successfully:`, imageUrl);
          } else {
            throw new Error(
              `Unexpected response format or status: ${response.status}`
            );
          }
        }
      );
    } else {
      setErrorMessage("Signature is empty.");
      setTimeout(() => {
        setErrorMessage("");
      }, 2500);
      console.log(`Signature ${index + 1} is empty or does not exist.`);
    }
  };

  const downloadPdf = async (d_f_name) => {
    //var url = d_f_name;
    if (d_f_name && d_f_name !== "") {
      let file_name = d_f_name;
      var url = ADMIN_URL + "files_data/form_uploads/" + d_f_name;
      fetch(url)
        .then((x) => x.blob())
        .then((b) => {
          // console.log("B INSTANCE", b instanceof Blob);
          const url = window.URL.createObjectURL(b);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = file_name;
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          this.setState({
            errorMessage:
              "There is some error while downloading the attachment.",
          });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 4000);
          // Handle any errors that occurred during the fetch
          console.error("Fetch error:", error);
        });
    } else {
      this.setState({
        errorMessage: "File not found.",
      });
      /*setTimeout(() => {
        this.setState({ errorMessage: "" });
      }, 4000);*/
    }
  };

  //Show Groups by sequence Number
  if (groups) {
    groups = groups.sort((a, b) => a.sequenceNumber - b.sequenceNumber);
  }

  const currentGroup = groups && groups.length > 0 ? groups[currentStep] : null;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className="form-renderer-container">
        {currentGroup ? (
          <>
            <div className="form-section">
              <div className="nk-block">
                <div className="card card-bordered">
                  <div className="nk-kycfm">
                    <div
                      className="nk-kycfm-head"
                      style={{ background: "#fff" }}
                    >
                      <div className="nk-kycfm-title">
                        <h5 className="title">
                          {formTitle || "Untitled Form"}
                        </h5>
                        {currentStep === 0 && (
                          <p className="sub-title">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: formDescription,
                              }}
                            />
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="nk-kycfm-content">
                      {responseMessage && (
                        <div
                          dangerouslySetInnerHTML={{ __html: responseMessage }}
                        ></div>
                      )}
                      {errorMessage && (
                        <div class="alert alert-danger alert-icon mb-3">
                          <em class="icon ni ni-cross-circle"></em>{" "}
                          {errorMessage}
                        </div>
                      )}
                      <div className="mb-3">
                        <h5 className="title">{currentGroup.group_title}</h5>
                        <p className="sub-title"
                            dangerouslySetInnerHTML={{
                              __html: currentGroup.group_desc
                                ? currentGroup.group_desc.replace(/\n/g, '<br />')
                                : ''
                            }}
                          ></p>
                      </div>
                      <div className="row g-gs">
                        {/*currentGroup.questions.map((questionId) => {
                          const question = formData[0].questions.find(
                            (q) => q.id === questionId
                          );
                          if (!question) {
                            return null;
                          }*/}

                        {currentGroup.questions
                          .map((questionId) => {
                            // Find the corresponding question from formData
                            const question = formData[0].questions.find(
                              (q) => q.id === questionId
                            );
                            return question;
                          })
                          .filter(Boolean) // Remove any undefined values if a question is not found
                          .sort((a, b) => a.sequenceNumber - b.sequenceNumber) // Sort by sequence number
                          .map((question) => {
                            const value = formState[question.id] || "";

                            return (
                              <div
                                key={question.id}
                                className={
                                  question.answerType === "label"
                                    ? "question-container col-sm-12"
                                    : "question-container col-sm-6"
                                }
                              >
                                <div className="form-group question-text mb-0">
                                  {question.answerType === "label" ? (
                                    <label
                                      className="form-label"
                                      style={{ fontSize: "1.275rem" }}
                                    >
                                      {question.questionText}{" "}
                                      {question.required && (
                                        <sup className="text-danger">*</sup>
                                      )}
                                    </label>
                                  ) : (
                                    <label className="form-label">
                                      {question.questionText}{" "}
                                      {question.required && (
                                        <sup className="text-danger">*</sup>
                                      )}
                                    </label>
                                  )}
                                  {question.imageUrl && (
                                    <>
                                      <a
                                        onClick={() =>
                                          downloadPdf(question.imageUrl)
                                        }
                                        style={{
                                          float: "right",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <span class="badge badge-outline-primary">
                                          <em class="icon ni ni-download"></em>{" "}
                                          Download File
                                        </span>
                                      </a>
                                    </>
                                  )}
                                </div>

                                {question.answerType === "input" && (
                                  <div className="form-group mb-0">
                                    <div className="form-control-wrap">
                                      <input
                                        className={`form-control form-control-lg`}
                                        onChange={(e) =>
                                          handleChange(
                                            question.id,
                                            e.target.value
                                          )
                                        }
                                        value={value}
                                        type="text"
                                        maxLength={100}
                                      />
                                    </div>
                                    {formErrors[question.id] && (
                                      <FormHelperText error>
                                        {formErrors[question.id]}
                                      </FormHelperText>
                                    )}
                                  </div>
                                )}
                                {question.answerType === "signature" && (
                                  <>
                                    <ul className="nav nav-tabs">
                                      <li className="nav-item">
                                        <a
                                          className={
                                            signatureType === "draw" ||
                                            signatureType === ""
                                              ? "nav-link active"
                                              : "nav-link"
                                          }
                                          data-toggle="tab"
                                          onClick={() =>
                                            setSignatureType("draw")
                                          }
                                          href="#tabItem6"
                                        >
                                          <em className="icon ni ni-edit-alt"></em>
                                          <span>Draw</span>
                                        </a>
                                      </li>
                                      {/*<li className="nav-item">
                                      <a
                                        className={
                                          signatureType === "choose"
                                            ? "nav-link active"
                                            : "nav-link"
                                        }
                                        data-toggle="tab"
                                        onClick={() =>
                                          setSignatureType("choose")
                                        }
                                        href="#tabItem5"
                                      >
                                        <em className="icon ni ni-list-check"></em>
                                        <span>Choose</span>
                                      </a>
                                    </li>*/}
                                    </ul>
                                    <div className="tab-content">
                                      <div
                                        className={
                                          signatureType === "choose"
                                            ? "tab-pane active"
                                            : "tab-pane"
                                        }
                                        id="tabItem5"
                                      >
                                        <div class="example-alert mb-4">
                                          <div class="alert alert-light">
                                            Pick a style for your signature
                                            (Authorized Person Name).
                                          </div>
                                        </div>
                                        <div className="form-group mb-4">
                                          <div className="form-control-wrap">
                                            <input
                                              className={`form-control form-control-lg`}
                                              onChange={(e) =>
                                                handleChangeSignature(
                                                  question.id,
                                                  e.target.value
                                                )
                                              }
                                              onInput={(e) => {
                                                e.target.value =
                                                  e.target.value.replace(
                                                    /[^a-zA-Z0-9 ]/gi,
                                                    ""
                                                  );
                                              }}
                                              value={signatureText}
                                              type="text"
                                              maxLength={100}
                                              placeholder="Authorized Person Name"
                                            />
                                          </div>
                                          {formErrors[question.id] && (
                                            <FormHelperText error>
                                              {formErrors[question.id]}
                                            </FormHelperText>
                                          )}
                                        </div>

                                        <div class="row">
                                          <div className="col-sm-6">
                                            <h6 className="title">
                                              Choose Style
                                            </h6>

                                            <ul className="cc-pay-method">
                                              <li className="cc-pay-dd dropdown">
                                                <a
                                                  href="#"
                                                  className="btn btn-white btn-outline-light dropdown-toggle dropdown-indicator"
                                                  data-toggle="dropdown"
                                                >
                                                  <span
                                                    style={{
                                                      fontFamily:
                                                        signatureClass,
                                                    }}
                                                  >
                                                    {signatureText ||
                                                      "Your Signature"}
                                                  </span>
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-auto">
                                                  <ul className="link-list-plain">
                                                    {cursiveFonts.map(
                                                      (font, index) => (
                                                        <li key={index}>
                                                          <a
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={() =>
                                                              setSignatureClass(
                                                                font.value
                                                              )
                                                            }
                                                            className="cc-pay-item"
                                                          >
                                                            <span className="cc-pay-item-name">
                                                              <span
                                                                className="cc-pay-item-method"
                                                                style={{
                                                                  fontFamily:
                                                                    font.value,
                                                                }}
                                                              >
                                                                {signatureText !==
                                                                ""
                                                                  ? signatureText
                                                                  : font.name}
                                                              </span>
                                                            </span>
                                                          </a>
                                                        </li>
                                                      )
                                                    )}
                                                  </ul>
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                          <div className="col-sm-6">
                                            <div
                                              className="signature-preview"
                                              id={`styled-text-container${question.id}`}
                                              style={{
                                                fontFamily: signatureClass,
                                                fontSize: "40px",

                                                height: "80px",
                                                background: "trasnparent",
                                                backgroundColor: "transparent",
                                                border: "none",
                                                lineHeight: "80px",
                                                color: "#000",
                                              }}
                                            >
                                              {signatureText ||
                                                "Your Signature"}
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          className="col-sm-12 mt-4 pl-0"
                                          style={{ clear: "both" }}
                                        >
                                          <button
                                            type="button"
                                            className="btn btn-primary"
                                            style={{
                                              margin: "13px 0px 12px 0px",
                                            }}
                                            onClick={() =>
                                              saveChooseImage(question.id)
                                            }
                                          >
                                            <em class="icon ni ni-folder-check"></em>{" "}
                                            <span>Save Signature</span>
                                          </button>
                                        </div>
                                      </div>
                                      <div
                                        className={
                                          signatureType === "draw" ||
                                          signatureType === ""
                                            ? "tab-pane active"
                                            : "tab-pane"
                                        }
                                        id="tabItem6"
                                      >
                                        <div className="row">
                                          <div className="col-sm-12">
                                            <div class="example-alert mb-4">
                                              <div class="alert alert-light">
                                                Please Save the signature to
                                                proceed.
                                              </div>
                                            </div>
                                            {formState[question.id] && (
                                              <div className="uploaded-file-preview">
                                                <img
                                                  src={`${APP_LIVE_URL}files_data/form_response_uploads/${
                                                    formState[question.id]
                                                  }`}
                                                  alt="Uploaded Signature"
                                                  style={{
                                                    maxWidth: "200px",
                                                  }}
                                                />
                                              </div>
                                            )}
                                            <div
                                              id={`divSig${question.id}`}
                                              style={{ display: "none" }}
                                            >
                                              <div class="mb-4">
                                                <img
                                                  src=""
                                                  class="clsimgSig"
                                                  height="100"
                                                  width="200"
                                                  onerror="this.style.display='none'"
                                                />
                                              </div>
                                            </div>
                                            <div>
                                              <strong>Signature</strong>
                                            </div>
                                          </div>

                                          <div className="col-sm-12">
                                            <SignatureCanvas
                                              clearOnResize={true}
                                              canvasProps={{
                                                className: styles.sigPad,
                                              }}
                                              velocityFilterWeight={0.7}
                                              throttle={50}
                                              ref={(ref) =>
                                                (sigPadRefs.current[
                                                  question.id
                                                ] = ref)
                                              }
                                            />
                                          </div>
                                          <div className="col-sm-12 mt-4">
                                            <button
                                              className="btn btn-warning"
                                              style={{
                                                margin: "13px 0px 12px 0px",
                                              }}
                                              onClick={() =>
                                                clearSignature(question.id)
                                              }
                                              type="button"
                                            >
                                              <em class="icon ni ni-delete"></em>{" "}
                                              <span>Clear</span>
                                            </button>
                                            {/*<button
                                            className="btn btn-success"
                                            style={{
                                              margin: "13px 0px 12px 10px",
                                            }}
                                            type="button"
                                            onClick={() =>
                                              previewSignature(question.id)
                                            }
                                          >
                                            <em class="icon ni ni-eye"></em>{" "}
                                            <span>Preview</span>
                                          </button>*/}
                                            <button
                                              type="button"
                                              className="btn btn-primary"
                                              style={{
                                                margin: "13px 0px 12px 10px",
                                              }}
                                              onClick={() =>
                                                saveSignature(question.id)
                                              }
                                            >
                                              <em class="icon ni ni-folder-check"></em>{" "}
                                              <span>Save Signature</span>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                                {question.answerType === "fixed" && (
                                  <>
                                    <div className="form-group mb-0">
                                      <div className="form-control-wrap">
                                        <input
                                          className={`form-control form-control-lg`}
                                          onChange={(e) =>
                                            handleChangeFixed(
                                              question.id,
                                              question.answer
                                            )
                                          }
                                          value={question.answer}
                                          type="text"
                                          maxLength={255}
                                        />
                                      </div>
                                      {formErrors[question.id] && (
                                        <FormHelperText error>
                                          {formErrors[question.id]}
                                        </FormHelperText>
                                      )}
                                    </div>
                                    <CopyToClipboard
                                      text={question.answer}
                                      onCopy={() => setCopied(true)}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <div class="form-note text-primary">
                                        <strong>
                                          Copy to Clipboard{" "}
                                          {copied ? (
                                            <span>
                                              <em className="icon ni ni-done"></em>
                                            </span>
                                          ) : null}
                                        </strong>
                                      </div>
                                    </CopyToClipboard>
                                  </>
                                )}
                                {question.answerType === "label" && (
                                  <div className="form-group mb-0">
                                    <h6></h6>
                                  </div>
                                )}
                                {question.answerType === "textarea" && (
                                  <div className="form-group">
                                    <div className="form-control-wrap">
                                      <textarea
                                        className={`form-control form-control-lg`}
                                        onChange={(e) =>
                                          handleChange(
                                            question.id,
                                            e.target.value
                                          )
                                        }
                                        placeholder=""
                                        value={value}
                                        maxLength={500}
                                      ></textarea>
                                    </div>
                                    {formErrors[question.id] && (
                                      <FormHelperText error>
                                        {formErrors[question.id]}
                                      </FormHelperText>
                                    )}
                                  </div>
                                )}
                                {question.answerType === "datetime" && (
                                  <div className="form-group">
                                    <DateTimePicker
                                      variant="inline"
                                      inputVariant="outlined"
                                      fullWidth
                                      value={formState[question.id] || null}
                                      onChange={(date) =>
                                        handleChange(question.id, date)
                                      }
                                    />
                                    {formErrors[question.id] && (
                                      <FormHelperText error>
                                        {formErrors[question.id]}
                                      </FormHelperText>
                                    )}
                                  </div>
                                )}
                                {question.answerType === "date" && (
                                  <div className="form-group">
                                    <DatePicker
                                      variant="inline"
                                      inputVariant="outlined"
                                      fullWidth
                                      value={formState[question.id] || null}
                                      onChange={(date) =>
                                        handleChange(question.id, date)
                                      }
                                    />
                                    {formErrors[question.id] && (
                                      <FormHelperText error>
                                        {formErrors[question.id]}
                                      </FormHelperText>
                                    )}
                                  </div>
                                )}
                                {question.answerType === "time" && (
                                  <div className="form-group">
                                    <TimePicker
                                      variant="inline"
                                      inputVariant="outlined"
                                      fullWidth
                                      value={formState[question.id] || null}
                                      onChange={(time) =>
                                        handleChange(question.id, time)
                                      }
                                    />
                                    {formErrors[question.id] && (
                                      <FormHelperText error>
                                        {formErrors[question.id]}
                                      </FormHelperText>
                                    )}
                                  </div>
                                )}
                                {question.answerType === "dropdown" && (
                                  <div className="form-group">
                                    <div className="form-control-wrap">
                                      <div className="form-control-select">
                                        <select
                                          className={`form-control form-control-lg`}
                                          onChange={(e) =>
                                            handleChange(
                                              question.id,
                                              e.target.value
                                            )
                                          }
                                          value={value}
                                        >
                                          <option value="">
                                            Select an option
                                          </option>
                                          {question.options.map(
                                            (option, index) => (
                                              <option
                                                key={index}
                                                value={option}
                                              >
                                                {option}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </div>
                                    </div>
                                    {formErrors[question.id] && (
                                      <FormHelperText error>
                                        {formErrors[question.id]}
                                      </FormHelperText>
                                    )}
                                  </div>
                                )}
                                {question.answerType === "checkbox" && (
                                  <>
                                    <div className="mt-2 prettyprint ">
                                      <div className="row">
                                        {question.options.map(
                                          (option, index) => (
                                            <div
                                              className="col-md-6 mb-1"
                                              key={`options_${index}`}
                                            >
                                              <div className="custom-control custom-control-md custom-checkbox custom-control-pro">
                                                <input
                                                  type="checkbox"
                                                  className="custom-control-input vdrSelected"
                                                  id={`question_checkbox_${question.id}_${index}`}
                                                  checked={
                                                    formState[
                                                      question.id
                                                    ]?.includes(option) || false
                                                  }
                                                  onChange={(e) =>
                                                    handleCheckboxChange(
                                                      question.id,
                                                      option,
                                                      e.target.checked
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="custom-control-label"
                                                  htmlFor={`question_checkbox_${question.id}_${index}`}
                                                >
                                                  {option}
                                                </label>
                                              </div>
                                              {formErrors[question.id] && (
                                                <FormHelperText error>
                                                  {formErrors[question.id]}
                                                </FormHelperText>
                                              )}
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </>
                                )}

                                {question.answerType === "radio" && (
                                  <div className="form-pm-group">
                                    <ul className="buysell-pm-list">
                                      {question.options.map((option, index) => (
                                        <li
                                          className="buysell-pm-item"
                                          key={index}
                                        >
                                          <input
                                            className="buysell-pm-control"
                                            type="radio"
                                            name={`radio_${question.id}`}
                                            id={`radio_${question.id}_${index}`}
                                            onChange={(e) =>
                                              handleChange(question.id, option)
                                            }
                                            value={option}
                                          />
                                          <label
                                            className="buysell-pm-label"
                                            htmlFor={`radio_${question.id}_${index}`}
                                          >
                                            <span className="pm-name">
                                              {option}
                                            </span>
                                            <span className="pm-icon">
                                              <em className="icon ni ni-question"></em>
                                            </span>
                                          </label>
                                        </li>
                                      ))}
                                    </ul>
                                    {formErrors[question.id] && (
                                      <FormHelperText error>
                                        {formErrors[question.id]}
                                      </FormHelperText>
                                    )}
                                  </div>
                                )}
                                {question.answerType === "image_input" && (
                                  <div className="form-group">
                                    <div
                                      className="form-control-group"
                                      id="fileContainer"
                                    >
                                      <input
                                        type="file"
                                        id={`add_picture_${question.id}`}
                                        className="file-block"
                                        name="add_picture"
                                        accept=".png, .jpg, .jpeg, .pdf, .doc, .docx, .xlx, .xlxs, .csv"
                                        onChange={(e) =>
                                          handleFileChange(question.id, e)
                                        }
                                      />
                                      {/* Display the uploaded file name or image preview if the URL exists in the state */}
                                      {formState[question.id] && (
                                        <div className="uploaded-file-preview">
                                          {formState[question.id].match(
                                            /\.(jpeg|jpg|png)$/i
                                          ) ? (
                                            <img
                                              src={`${APP_LIVE_URL}files_data/form_response_uploads/${
                                                formState[question.id]
                                              }`}
                                              alt="Uploaded preview"
                                              style={{
                                                maxWidth: "100px",
                                              }}
                                            />
                                          ) : (
                                            <p>
                                              {formState[question.id]
                                                .split("/")
                                                .pop()}
                                            </p> // Display file name for non-images
                                          )}
                                        </div>
                                      )}
                                    </div>
                                    {formErrors[question.id] && (
                                      <FormHelperText error>
                                        {formErrors[question.id]}
                                      </FormHelperText>
                                    )}
                                  </div>
                                )}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
              }}
            >
              {currentStep > 0 && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setCurrentStep(currentStep - 1)}
                >
                  Back
                </Button>
              )}
              {currentStep < groups.length - 1 ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNextStep}
                  style={{ marginLeft: "10px" }}
                >
                  Continue
                </Button>
              ) : (
                <>
                  {allow_submit === 1 && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                      style={{ marginLeft: "10px" }}
                      disabled={loading}
                    >
                      {loading ? "Loading..." : "Submit"}
                    </Button>
                  )}
                  {allow_submit === 0 && (
                    <button
                      type="button"
                      class="btn btn-outline-warning"
                      style={{ marginLeft: "10px" }}
                    >
                      Submission is disabled for this form.
                    </button>
                  )}
                </>
              )}
            </div>
          </>
        ) : (
          hasUngroupedQuestions && (
            <>
              <div className="form-section">
                <div className="nk-block">
                  <div className="card card-bordered">
                    <div className="nk-kycfm">
                      <div
                        className="nk-kycfm-head"
                        style={{ background: "#f5f6fa" }}
                      >
                        <div className="nk-kycfm-title">
                          <h5 className="title">
                            {formTitle || "Untitled Form"}
                          </h5>
                          <p className="sub-title">
                            {formDescription || "Untitled Form"}
                          </p>
                        </div>
                      </div>
                      <div className="nk-kycfm-content">
                        {responseMessage && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: responseMessage,
                            }}
                          ></div>
                        )}
                        {errorMessage && (
                          <div class="alert alert-danger alert-icon mb-3">
                            <em class="icon ni ni-cross-circle"></em> Please
                            fill out all the required questions!
                          </div>
                        )}
                        <div className="row g-gs">
                          {ungroupedQuestions.map((question) => (
                            <div
                              key={question.id}
                              className="question-container col-sm-6"
                            >
                              <div className="form-group question-text mb-0">
                                <label className="form-label">
                                  {question.questionText}{" "}
                                  {question.required && (
                                    <sup className="text-danger">*</sup>
                                  )}
                                </label>
                              </div>
                              {question.imageUrl && (
                                <img
                                  src={question.imageUrl}
                                  alt="Admin Uploaded"
                                  className="question-image"
                                />
                              )}
                              {question.answerType === "input" && (
                                <div className="form-group mb-0">
                                  <div className="form-control-wrap">
                                    <input
                                      className={`form-control form-control-lg`}
                                      onChange={(e) =>
                                        handleChange(
                                          question.id,
                                          e.target.value
                                        )
                                      }
                                      type="text"
                                      maxLength={100}
                                    />
                                  </div>
                                  {formErrors[question.id] && (
                                    <FormHelperText error>
                                      {formErrors[question.id]}
                                    </FormHelperText>
                                  )}
                                </div>
                              )}
                              {question.answerType === "textarea" && (
                                <div className="form-group">
                                  <div className="form-control-wrap">
                                    <textarea
                                      className={`form-control form-control-lg`}
                                      onChange={(e) =>
                                        handleChange(
                                          question.id,
                                          e.target.value
                                        )
                                      }
                                      placeholder=""
                                      maxLength={500}
                                    ></textarea>
                                  </div>
                                  {formErrors[question.id] && (
                                    <FormHelperText error>
                                      {formErrors[question.id]}
                                    </FormHelperText>
                                  )}
                                </div>
                              )}
                              {question.answerType === "datetime" && (
                                <div className="form-group">
                                  <DateTimePicker
                                    variant="inline"
                                    inputVariant="outlined"
                                    fullWidth
                                    value={formState[question.id] || null}
                                    onChange={(date) =>
                                      handleChange(question.id, date)
                                    }
                                  />
                                  {formErrors[question.id] && (
                                    <FormHelperText error>
                                      {formErrors[question.id]}
                                    </FormHelperText>
                                  )}
                                </div>
                              )}
                              {question.answerType === "date" && (
                                <div className="form-group">
                                  <DatePicker
                                    variant="inline"
                                    inputVariant="outlined"
                                    fullWidth
                                    value={formState[question.id] || null}
                                    onChange={(date) =>
                                      handleChange(question.id, date)
                                    }
                                  />
                                  {formErrors[question.id] && (
                                    <FormHelperText error>
                                      {formErrors[question.id]}
                                    </FormHelperText>
                                  )}
                                </div>
                              )}
                              {question.answerType === "time" && (
                                <div className="form-group">
                                  <TimePicker
                                    variant="inline"
                                    inputVariant="outlined"
                                    fullWidth
                                    value={formState[question.id] || null}
                                    onChange={(time) =>
                                      handleChange(question.id, time)
                                    }
                                  />
                                  {formErrors[question.id] && (
                                    <FormHelperText error>
                                      {formErrors[question.id]}
                                    </FormHelperText>
                                  )}
                                </div>
                              )}
                              {question.answerType === "dropdown" && (
                                <div className="form-group">
                                  <div className="form-control-wrap">
                                    <div className="form-control-select">
                                      <select
                                        className={`form-control form-control-lg`}
                                        onChange={(e) =>
                                          handleChange(
                                            question.id,
                                            e.target.value
                                          )
                                        }
                                      >
                                        <option value="">
                                          Select an option
                                        </option>
                                        {question.options.map(
                                          (option, index) => (
                                            <option key={index} value={option}>
                                              {option}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </div>
                                  </div>
                                  {formErrors[question.id] && (
                                    <FormHelperText error>
                                      {formErrors[question.id]}
                                    </FormHelperText>
                                  )}
                                </div>
                              )}
                              {question.answerType === "checkbox" && (
                                <>
                                  <div className="mt-2 prettyprint ">
                                    <div className="row">
                                      {question.options.map((option, index) => (
                                        <div
                                          className="col-md-6 mb-1"
                                          key={`options_${index}`}
                                        >
                                          <div className="custom-control custom-control-md custom-checkbox custom-control-pro">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input vdrSelected"
                                              id={`question_checkbox_${question.id}_${index}`}
                                              checked={
                                                formState[
                                                  question.id
                                                ]?.includes(option) || false
                                              }
                                              onChange={(e) =>
                                                handleCheckboxChange(
                                                  question.id,
                                                  option,
                                                  e.target.checked
                                                )
                                              }
                                            />
                                            <label
                                              className="custom-control-label"
                                              htmlFor={`question_checkbox_${question.id}_${index}`}
                                            >
                                              {option}
                                            </label>
                                          </div>
                                          {formErrors[question.id] && (
                                            <FormHelperText error>
                                              {formErrors[question.id]}
                                            </FormHelperText>
                                          )}
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </>
                              )}

                              {question.answerType === "radio" && (
                                <div className="form-pm-group">
                                  <ul className="buysell-pm-list">
                                    {question.options.map((option, index) => (
                                      <li
                                        className="buysell-pm-item"
                                        key={index}
                                      >
                                        <input
                                          className="buysell-pm-control"
                                          type="radio"
                                          name={`radio_${question.id}`}
                                          id={`radio_${question.id}_${index}`}
                                          onChange={(e) =>
                                            handleChange(question.id, option)
                                          }
                                        />
                                        <label
                                          className="buysell-pm-label"
                                          htmlFor={`radio_${question.id}_${index}`}
                                        >
                                          <span className="pm-name">
                                            {option}
                                          </span>
                                          <span className="pm-icon">
                                            <em className="icon ni ni-question"></em>
                                          </span>
                                        </label>
                                      </li>
                                    ))}
                                  </ul>
                                  {formErrors[question.id] && (
                                    <FormHelperText error>
                                      {formErrors[question.id]}
                                    </FormHelperText>
                                  )}
                                </div>
                              )}
                              {question.answerType === "image_input" && (
                                <div className="form-group">
                                  <div
                                    className="form-control-group"
                                    id="fileContainer"
                                  >
                                    <input
                                      type="file"
                                      id={`add_picture_${question.id}`}
                                      className="file-block"
                                      name="add_picture"
                                      accept=".png, .jpg, .jpeg, .pdf, .doc, .docx, .xlx, .xlxs, .csv"
                                      onChange={(e) =>
                                        handleFileChange(question.id, e)
                                      }
                                    />
                                    {/* Display the uploaded file name or image preview if the URL exists in the state */}
                                    {formState[question.id] && (
                                      <div className="uploaded-file-preview">
                                        {formState[question.id].match(
                                          /\.(jpeg|jpg|png)$/i
                                        ) ? (
                                          <img
                                            src={`${APP_LIVE_URL}files_data/form_response_uploads/${
                                              formState[question.id]
                                            }`}
                                            alt="Uploaded preview"
                                            style={{
                                              maxWidth: "100px",
                                            }}
                                          />
                                        ) : (
                                          <p>
                                            {formState[question.id]
                                              .split("/")
                                              .pop()}
                                          </p> // Display file name for non-images
                                        )}
                                      </div>
                                    )}
                                  </div>
                                  {formErrors[question.id] && (
                                    <FormHelperText error>
                                      {formErrors[question.id]}
                                    </FormHelperText>
                                  )}
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "20px",
                }}
              >
                {allow_submit === 1 && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    style={{ marginLeft: "10px" }}
                  >
                    Submit
                  </Button>
                )}
              </div>
            </>
          )
        )}
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default FormRendererAgent;
